import React, { Fragment, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Header from "./Header";
import { isMobile } from "react-device-detect";
import imageWhoWeAre from "../images/who_we_are.png";
import imageWhatWeOffering from "../images/what_we_offering.png";
import imageContact from "../images/contact.png";
import servicesIcon1 from "../images/services_icon1.svg";
import servicesIcon2 from "../images/services_icon2.svg";
import servicesIcon3 from "../images/services_icon3.svg";
import servicesIcon4 from "../images/services_icon4.svg";

const Main = () => {
    const [index, setIndex] = useState(0);
    const [showServices, setShowServices] = useState(false);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Fragment>
            <Header handleSelect={handleSelect} />
            <Carousel
                id='carousel'
                className='carousel'
                controls={false}
                indicators={false}
                interval={null}
                touch={true}
                fade={isMobile}
                activeIndex={index}
                onSlid={window.scrollTo({top: 0})}
            >
                <Carousel.Item className="home">
                    <div className="carousel__item-inner">
                        <Container>
                            <h1>It's time to make a difference.</h1>
                            <div className="carousel__item-text">
                                <button className="button" onClick={()=>handleSelect(1)}>About us</button>
                                <p>
                                    <span>GGR CONSULTING</span> is an expert IT team at your service. The team will bring their expertises in your business and will adapt to your constraints
                                </p>
                            </div>
                        </Container>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="about">
                    <div className="carousel__item-inner">
                        <Container>
                            <div className="carousel__item-row">
                                <div className="carousel__item-col carousel__item-text">
                                    <h2>Who we are ?</h2>
                                    <p>
                                        The <span>GGR Consulting</span> team helps dozens of clients each month across various industries. Our team will adapt to your business needs and budget. Say goodbye to long meetings and endless project management. We are goal-oriented.
                                    </p>
                                    <p>
                                        Used to work in sensitive projects with large constraints, the team is looking for challenges. Everyone is there to help you to reach your targets.
                                    </p>
                                    <button className="button" onClick={()=>handleSelect(2)}>What we’re offering</button>
                                </div>
                                <div className="carousel__item-col carousel__item-image">
                                    <h2>Who we are ?</h2>
                                    <img src={imageWhoWeAre} alt="Who we are ?"/>
                                </div>
                            </div>
                        </Container>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="offers">
                    <div className="carousel__item-inner">
                        <Container>
                        <div className="carousel__item-row">
                            <div className="carousel__item-col carousel__item-text">
                                <h2>What we’re offering ?</h2>
                                <p>
                                    Data Science, Advanced IT development, Web Security, Pen Testing, Devops. <br />
                                    We’re helping to build a team from scratch or leading your IT team, our competences are very large. Let’s be in touch to understand your need.
                                </p>
                                <p>
                                    <span className="link" onClick={() => setShowServices(true)}>Read more...</span>
                                </p>
                                <button className="button" onClick={() => handleSelect(3)}>Contact us</button>
                            </div>
                            <div className="carousel__item-col carousel__item-image">
                                <h2>What we’re offering ?</h2>
                                <img src={imageWhatWeOffering} alt="What we’re offering ?"/>
                            </div>
                        </div>
                        </Container>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="contact">
                    <div className="carousel__item-inner">
                        <Container>
                            <div className="carousel__item-row">
                                <div className="carousel__item-col carousel__item-text">
                                    <h2>Say hello to our team !</h2>
                                    <p>
                                        <span>GGR CONSULTING</span> is available to reply to your need.<br /> Feel free to contact us to have a coffee together (drinks are on us ;))
                                    </p>
                                    <p>
                                        <a className="link" href="mailto:contact@ggr-consulting.com">contact@ggr-consulting.com</a>
                                    </p>
                                    <a href="mailto:contact@ggr-consulting.com" className="button">Write an email</a>
                                </div>
                                <div className="carousel__item-col carousel__item-image">
                                    <h2>Say hello to our team !</h2>
                                    <img src={imageContact} alt="Contact us"/>
                                </div>
                            </div>
                        </Container>
                    </div>
                </Carousel.Item>
            </Carousel>
            <Modal
                show={showServices}
                onHide={() => {setShowServices(false)}}
                fullscreen={true}
                animation={true}
                centered
                scrollable
                className="services"
            >
                <Modal.Header>
                    <button type="button" className="button-close" onClick={() => setShowServices(false)} aria-label="Close">
                        <span className="button-close-icon">
                            <span>Back</span>
                        </span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <h2>Our services</h2>
                    <div className="services__body">
                        <div className="services__body-item">
                            <img src={servicesIcon1} alt="Data Science"/>
                            <h4>Data Science</h4>
                            <p>
                                Data science is all around data mining and data analysis. Data and it is the most precious asset for modern businesses. Like mining diamonds, extracting relevant information from an unorganized data set is a difficult task but not for us.
                            </p>
                        </div>
                        <div className="services__body-item">
                            <img src={servicesIcon2} alt="Web Security"/>
                            <h4>Web Security</h4>
                            <p>
                                We are taking security and privacy very seriously as it keeps our client business safe and we aknowledge that safety comes first when it comes to sensitive data. Our solutions are proven by time.
                            </p>
                        </div>
                        <div className="services__body-item">
                            <img src={servicesIcon3} alt="Advanced IT development"/>
                            <h4>Advanced IT development</h4>
                            <p>
                                We are creating outstanding web systems to support your business with a backbone for presence online. We use only well tested and thought strategies for developing simple and complex solutions for individual business needs.
                            </p>
                        </div>
                        <div className="services__body-item">
                            <img src={servicesIcon4} alt="Devops"/>
                            <h4>Devops</h4>
                            <p>
                                Companies that have adopted devops principles are innovating faster and leaving competitors behind. We are not an exception - we use it ourselves and we offer our service to take advantage of this oppportunity for our clients.
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default Main;
