import React, { Fragment, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import logo from '../images/top_logo.svg'
import Modal from "react-bootstrap/Modal";

const Header = ({ handleSelect }) => {
    const [menuOpened, setMenuOpened] = useState(false);
    const [showLegalMentions, setShowLegalMentions] = useState(false);

    const handleMenu = () => {
        setMenuOpened(!menuOpened);
    };

    const toggleNavClass = (e) => {
        e.classList.toggle('opened');
    };

    return (
        <Fragment>
            <Navbar collapseOnSelect expand={false} className={`${menuOpened ? 'black' : ''}`} id="navigaton" variant="dark">
                <Container fluid className='header'>
                    <img
                        onClick={() => handleSelect(0)}
                        src={logo}
                        width="50"
                        height="60"
                        className="d-inline-block align-top header__logo"
                        alt="logo"
                    />
                    <button type="button" className={`navbar-toggler ${!menuOpened ? "collapsed" : ""}`} onClick={handleMenu}>
                    <span className="navbar-toggler-icon">
                    </span>
                    </button>
                    <Navbar.Collapse in={menuOpened} onEntered={toggleNavClass}>
                        <Container>
                            <div className="navbar-links">
                                <a onClick={() => {handleSelect(0); handleMenu()}}>Home</a>
                                <a onClick={() => {handleSelect(1); handleMenu()}}>Who we are</a>
                                <a onClick={() => {handleSelect(2); handleMenu()}}>What we’re offering</a>
                                <a onClick={() => {handleSelect(3); handleMenu()}}>Contact us</a>
                            </div>
                            <div className="legal-mentions" onClick={() => setShowLegalMentions(true)}>Legal Mentions</div>
                        </Container>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="legal-mentions" onClick={() => setShowLegalMentions(true)}>Legal Mentions</div>
            <Modal
                show={showLegalMentions}
                onHide={() => setShowLegalMentions(false)}
                fullscreen={true}
                animation={true}
                centered
                scrollable
            >
                <Modal.Header>
                    <button type="button" className="button-close" onClick={() => setShowLegalMentions(false)} aria-label="Close">
                        <span className="button-close-icon">
                            <span>Back</span>
                        </span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <h2>Legal Mentions</h2>
                    <p>
                        This website is the property of the GGR CONSULTING. Company address: 4 rue de la République 69001 Lyon FRANCE.
                    </p>
                </Modal.Body>
            </Modal>
        </Fragment>

    )
}

export default Header;
